import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: FOF;
    src: url("/fonts/FOFBB.TTF");
  }
  @font-face {
    font-family: SUISSE_REGULAR;
    src: url("/fonts/Synonym-Regular.otf");
  }
  @font-face {
    font-family: SUISSE_MEDIUM;
    src: url("/fonts/Synonym-Regular.otf");
  }
  @font-face {
    font-family: SUISSE_BOLD;
    src: url("/fonts/Synonym-Medium.otf");
  }
  @font-face {
    font-family: SUISSE_BLACK;
    src: url("/fonts/Synonym-Bold.otf");
  }

  * {
    font-family: SUISSE_REGULAR;
  }

  html {
    font-size: 62.5%;
  }

  html, body {
    scroll-behavior: smooth;
    background : rgba(13,15,17);
  }
  
  body {
    background : rgba(13,15,17);
    font-family: SUISSE_REGULAR;
    font-size: 1.4rem;
    line-height: 1.75rem;
    color: #FFF;
    overflow-x: hidden;
    img {
      height: auto;
      max-width: 100%;
    }
  }

  /* CIRCLE DYNAMIC */
  .fancy-carousel-element {
    box-shadow: none !important;
    transition : 60s transform !important;
    will-change: transform;
  }
  .fancy-carousel {
    transition : 60s transform !important;
    will-change: transform;
  }

  @media screen and (max-width: 1199px) {
    .wrapContent {
      padding : 80px 0px 150px 0px !important;
    }
    .blockContainer {
      max-width: unset !important;
      min-width: unset !important;
      margin: 0px !important;
      padding: 0px 15px !important;
    }
  }

  .blockContainer {
    max-width: 1300px;
    min-width: 800px;
    margin: 0 auto;
    padding: 0px 15px;
  }

  .wrapContent {
    padding : 0px 0px 0px 200px;
  }

  #__next {
    position: relative;
    z-index: 1;
  }

  #portal-root {
    position: relative;
    z-index: 2;
  }

  @keyframes upDown {
    from {
      transform : translate(10px, -10px) rotate(-10deg);
    }
    to {
      transform : translate(30px, 10px) rotate(0deg) ;
    }
  }
  @keyframes rotateCircle {
    0% {
      transform: rotate(0);
    }
  
    25% {
      transform: rotate(90deg);
    }
  
    50% {
      transform: rotate(180deg);
    }
  
    75% {
      transform: rotate(270deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  .blockTrailer__modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    top: 0px;
    z-index: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    -webkit-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    z-index: -1;
    background: rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 60px 0px 0px 0px;
  }
  .blockTrailer__modal.show {
    opacity: 1;
    z-index: 9999;
    -webkit-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
  }
  .blockTrailer__modal &.blockTrailer__modal--video {
    width: 100%;
    height: calc(100vh - 60px);
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.6s cubic-bezier(0.65, 0.12, 0.26, 1);
    margin: 0 auto;
  }
  .blockTrailer__modal--tool {
    position: absolute;
    top: 50px;
    right: 15px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 2;
  }
  .blockTrailer__modal .tool__item {
    cursor: pointer;
    width: 50px;
    background: rgba(0,0,0,0.6);
    backdrop-filter: blur(20px);
    height: 50px;
    margin: 20px 0px 0px 0px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.4s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.4s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.4s cubic-bezier(0.65, 0.12, 0.26, 1);
  }
  .blockTrailer__modal .tool__item--img {
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .blockTrailer__modal .tool__item:hover {
    opacity: 0.8;
    -webkit-transition: 0.4s cubic-bezier(0.65, 0.12, 0.26, 1);
    -o-transition: 0.4s cubic-bezier(0.65, 0.12, 0.26, 1);
    transition: 0.4s cubic-bezier(0.65, 0.12, 0.26, 1);
  }
  .item__img {
    width: 100%;
    height: 100%;
  }


  // PRESALE
  .blockPresale {
    background: #12c2e9;
    background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9); 
    background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9); 
    padding: 30px 0px 35px 0px;
    margin : -5px 0px 0px 0px;
  }
  .blockPresale .blockPresale__wrap--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width : 1199px) {
      flex-direction : column;
    }
  }
  .blockPresale .content__brand {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width : 1199px) {
      width : 100%;
    }
  }
  .blockPresale .content__brand--text {
    font-family: SUISSE_BOLD;
    font-size: 2.8rem;
    line-height: calc(2.8rem * 1.25);
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    @media screen and (max-width : 1199px) {
      font-size: 1.8rem;
      line-height: calc(1.8rem * 1.25);
      display : flex;
      span {
        margin : 0px 0px 0px 5px;
      }
    }
  }
  .blockPresale .content__brand--text span {
    display: block;
    font-family: SUISSE_BOLD;
  }
  .blockPresale .content__buy {
    width: 25%;
  }
  .blockPresale .content__countdown {
    width: 50%;
    padding: 0px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width : 1199px) {
      margin : 15px 0px 35px 0px;
      width: 100%;
    }
  }
  .blockPresale .content__countdown--text {
    text-align: center;
    font-family: SUISSE_BOLD;
    font-size: 1.4rem;
    line-height: calc(1.4rem * 1.25);
    color: #FFF;
    margin : 0px 0px 20px 0px;
  }
  .blockPresale .listTime__wrap--listItem {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blockPresale .content__countdown--listTime {
    margin: 0px 0px 0px 0px;
  }
  .blockPresale .listItem__item {
    width: 55px;
    height: 40px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 2rem;
    line-height: calc(2rem * 1.25);
    margin: 0px 10px;
    position: relative;
    color: #FFF;
    font-family: SUISSE_BOLD;
  }
  .blockPresale .listItem__item::before {
    content: ':';
    position: absolute;
    left: -12.5px;
    color: #FFF;
  }
  .blockPresale .listItem__item:first-child:before {
    color: transparent;
  }
  .blockPresale .content__buy {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width : 1199px) {
      width: 100%;
    }
  }
  .blockPresale .buy__content--btnBuy {
    background: #000;
    padding: 20px;
    border-radius: 10px;
    transition: 0.4s all;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .blockPresale .btnBuy__text {
    font-family: SUISSE_BOLD;
    font-size: 1.6rem;
    line-height: calc(1.6rem * 1.25);
    text-transform: uppercase;
    position: relative;
  }
  .blockPresale .buy__content--btnBuy:hover {
    background: #222;
    transition: 0.4s all;
  }
  .blockPresale .btnBuy__img {
    width: 20px;
    height: 20px;
    margin: 0px 40px 0px 0px;
    filter : invert(1);
  }
  .blockPresale .btnBuy__text::before {
    width: 2px;
    height: 18px;
    position: absolute;
    left: -19px;
    top : 3px;
    background: #FFF;
    content: '';
  }

  @keyframes scaleAni {
    from {
      transform : scale(1);
    }
    to {
      transform : scale(1.1);
    }
  }
  
`

export default GlobalStyle
