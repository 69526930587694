import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | AngryBirdInu',
  defaultTitle: 'AngryBirdInu',
  description: 'AngryBirdInu',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@AngryBirdInu',
    site: '@AngryBirdInu',
  },
  openGraph: {
    title: 'AngryBirdInu',
    description: 'AngryBirdInu',
    images: [{ url: 'https://angrybirdinu.com/logo.png' }],
  },
}
