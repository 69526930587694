import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;
  transition: 0.4s all;
  border-radius: 8px;
  margin: 0px 7.5px;
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  width: 100%;
  background-image: url(../images/bgButton.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 35px;
  padding: 0px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.4s all;
  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    @media screen and (max-width: 1199px) {
      justify-content: center;
      flex-direction: column-reverse;
    }
  }
  &:hover {
    filter: brightness(0.9);
  }
  p {
    color: ${({ $isActive }) => ($isActive ? "rgba(163, 213, 118)" : "#C1C1C1")};
  }
  svg {
    display: none;
    width: 20px;
    fill: ${({ $isActive }) => ($isActive ? "rgba(163, 213, 118)" : "#C1C1C1")};
  }
  @media screen and (max-width: 1199px) {
    margin: 10px 0px;
  }
`;

export const StyledName = styled.div`
  user-select: none;
  p {
    font-family: FOF;
    font-size: 2rem;
    color: #fff;
    line-height: calc(2rem * 1.25);
  }
`;

export const TextMenu = styled.div`
  color: #000;
  font-family: SUISSE_BOLD;
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.25);
`;

export default StyledMenuItem;
