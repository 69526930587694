/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo, useState } from "react";
import styled from "styled-components";
import isTouchDevice from "../../util/isTouchDevice";
import MenuItem from "../MenuItem/MenuItem";
import { MenuItemsProps } from "./types";
import { Button } from "../Button";

const BlockMenu = styled.div`
  position: fixed;
  width: fit-content;
  height: 60px;
  z-index: 1000;
  will-change: transform;
  backface-visibility: hidden;
  transition: 0.4s all;
  border-radius: 0px;
  flex-direction: row;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1199px) {
    width: 100%;
    height: 100vh;
    top: 0;
    flex-direction: column;
    right: -100%;
    left: unset;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    &.active {
      right: 0px;
    }
  }
`;

const WrapMenuMobile = styled.div`
  position: fixed;
  height: 35px;
  top: 12.5px;
  width: 35px;
  z-index: 1001;
  right: 15px;
  button {
    padding: 0px;
    border-radius: 8px;
    width: 35px;
    height: 35px;
  }
  display: none;
  @media screen and (max-width: 1199px) {
    display: flex;
  }
`;

const WrapMenu = styled.div`
  position: relative;
`;

const ImageMNMB = styled.img`
  width: 18px;
  height: 18px;
  filter: invert(1);
`;

const MenuItems: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({ children, items = [], activeItem }) => {
  const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };
  const handleMenuItemClick = () => {
    setShowMenu(false);
  };
  return (
    <WrapMenu>
      <BlockMenu className={`${showMenu && "active"}`}>
        {items.map(({ label, items: menuItems = [], href, icon, image, disabled }) => {
          const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
          const isActive = activeItem === href;
          const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href };
          const Icon = icon;
          return (
            <MenuItem
              key={`${label}#${href}`}
              {...linkProps}
              image={image}
              isActive={isActive}
              statusColor={statusColor}
              isDisabled={disabled}
              onItemClick={handleMenuItemClick}
            >
              <p>{label}</p>
              {icon && createElement(Icon as any, { color: isActive ? "invertedContrast" : "textSubtle" })}
            </MenuItem>
          );
        })}
        {children}
      </BlockMenu>
      <WrapMenuMobile>
        <Button onClick={handleShowMenu}>
          <ImageMNMB src="/images/icMenuMobile.svg" />
        </Button>
      </WrapMenuMobile>
    </WrapMenu>
  );
};

export default memo(MenuItems);
